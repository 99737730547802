import photo1 from "/public/images/events/championnat/championnat-technique-18/photo1.webp";
import photo2 from "/public/images/events/championnat/championnat-technique-18/photo2.webp";
import photo3 from "/public/images/events/championnat/championnat-technique-18/photo3.webp";

export const championnatTechnique18Images = [
  {
    src: photo1,
    alt: "Championnat Technique 2018 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Championnat Technique 2018 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Championnat Technique 2018 - Photo 3",
    format: "2:3" as const,
  },
];
