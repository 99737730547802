import photo1 from "/public/images/events/coree/2013/photo1.webp";
import photo2 from "/public/images/events/coree/2013/photo2.webp";
import photo3 from "/public/images/events/coree/2013/photo3.webp";
import photo4 from "/public/images/events/coree/2013/photo4.webp";
import photo5 from "/public/images/events/coree/2013/photo5.webp";
import photo6 from "/public/images/events/coree/2013/photo6.webp";
import photo7 from "/public/images/events/coree/2013/photo7.webp";
import photo8 from "/public/images/events/coree/2013/photo8.webp";
import photo9 from "/public/images/events/coree/2013/photo9.webp";
import photo10 from "/public/images/events/coree/2013/photo10.webp";
import photo11 from "/public/images/events/coree/2013/photo11.webp";
import photo12 from "/public/images/events/coree/2013/photo12.webp";
import photo13 from "/public/images/events/coree/2013/photo13.webp";
import photo14 from "/public/images/events/coree/2013/photo14.webp";
import photo15 from "/public/images/events/coree/2013/photo15.webp";
import photo16 from "/public/images/events/coree/2013/photo16.webp";
import photo17 from "/public/images/events/coree/2013/photo17.webp";
import photo18 from "/public/images/events/coree/2013/photo18.webp";
import photo19 from "/public/images/events/coree/2013/photo19.webp";
import photo20 from "/public/images/events/coree/2013/photo20.webp";
import photo21 from "/public/images/events/coree/2013/photo21.webp";
import photo22 from "/public/images/events/coree/2013/photo22.webp";
import photo23 from "/public/images/events/coree/2013/photo23.webp";
import photo24 from "/public/images/events/coree/2013/photo24.webp";
import photo25 from "/public/images/events/coree/2013/photo25.webp";
import photo26 from "/public/images/events/coree/2013/photo26.webp";
import photo27 from "/public/images/events/coree/2013/photo27.webp";
import photo28 from "/public/images/events/coree/2013/photo28.webp";
import photo29 from "/public/images/events/coree/2013/photo29.webp";
import photo30 from "/public/images/events/coree/2013/photo30.webp";
import photo31 from "/public/images/events/coree/2013/photo31.webp";
import photo32 from "/public/images/events/coree/2013/photo32.webp";
import photo33 from "/public/images/events/coree/2013/photo33.webp";
import photo34 from "/public/images/events/coree/2013/photo34.webp";
import photo35 from "/public/images/events/coree/2013/photo35.webp";
import photo36 from "/public/images/events/coree/2013/photo36.webp";
import photo37 from "/public/images/events/coree/2013/photo37.webp";
import photo38 from "/public/images/events/coree/2013/photo38.webp";
import photo39 from "/public/images/events/coree/2013/photo39.webp";
import photo40 from "/public/images/events/coree/2013/photo40.webp";
import photo41 from "/public/images/events/coree/2013/photo41.webp";
import photo42 from "/public/images/events/coree/2013/photo42.webp";
import photo43 from "/public/images/events/coree/2013/photo43.webp";
import photo44 from "/public/images/events/coree/2013/photo44.webp";
import photo45 from "/public/images/events/coree/2013/photo45.webp";
import photo46 from "/public/images/events/coree/2013/photo46.webp";
import photo47 from "/public/images/events/coree/2013/photo47.webp";
import photo48 from "/public/images/events/coree/2013/photo48.webp";
import photo49 from "/public/images/events/coree/2013/photo49.webp";
import photo50 from "/public/images/events/coree/2013/photo50.webp";
import photo51 from "/public/images/events/coree/2013/photo51.webp";
import photo52 from "/public/images/events/coree/2013/photo52.webp";
import photo53 from "/public/images/events/coree/2013/photo53.webp";
import photo54 from "/public/images/events/coree/2013/photo54.webp";
import photo55 from "/public/images/events/coree/2013/photo55.webp";
import photo56 from "/public/images/events/coree/2013/photo56.webp";
import photo57 from "/public/images/events/coree/2013/photo57.webp";
import photo58 from "/public/images/events/coree/2013/photo58.webp";
import photo59 from "/public/images/events/coree/2013/photo59.webp";
import photo60 from "/public/images/events/coree/2013/photo60.webp";
import photo61 from "/public/images/events/coree/2013/photo61.webp";
import photo62 from "/public/images/events/coree/2013/photo62.webp";
import photo63 from "/public/images/events/coree/2013/photo63.webp";
import photo64 from "/public/images/events/coree/2013/photo64.webp";
import photo65 from "/public/images/events/coree/2013/photo65.webp";
import photo66 from "/public/images/events/coree/2013/photo66.webp";
import photo67 from "/public/images/events/coree/2013/photo67.webp";
import photo68 from "/public/images/events/coree/2013/photo68.webp";
import photo69 from "/public/images/events/coree/2013/photo69.webp";
import photo70 from "/public/images/events/coree/2013/photo70.webp";
import photo71 from "/public/images/events/coree/2013/photo71.webp";
import photo72 from "/public/images/events/coree/2013/photo72.webp";
import photo73 from "/public/images/events/coree/2013/photo73.webp";
import photo74 from "/public/images/events/coree/2013/photo74.webp";
import photo75 from "/public/images/events/coree/2013/photo75.webp";
import photo76 from "/public/images/events/coree/2013/photo76.webp";
import photo77 from "/public/images/events/coree/2013/photo77.webp";
import photo78 from "/public/images/events/coree/2013/photo78.webp";
import photo79 from "/public/images/events/coree/2013/photo79.webp";
import photo80 from "/public/images/events/coree/2013/photo80.webp";
import photo81 from "/public/images/events/coree/2013/photo81.webp";
import photo82 from "/public/images/events/coree/2013/photo82.webp";
import photo83 from "/public/images/events/coree/2013/photo83.webp";
import photo84 from "/public/images/events/coree/2013/photo84.webp";
import photo85 from "/public/images/events/coree/2013/photo85.webp";
import photo87 from "/public/images/events/coree/2013/photo87.webp";
import photo88 from "/public/images/events/coree/2013/photo88.webp";
import photo89 from "/public/images/events/coree/2013/photo89.webp";
import photo90 from "/public/images/events/coree/2013/photo90.webp";
import photo91 from "/public/images/events/coree/2013/photo91.webp";
import photo92 from "/public/images/events/coree/2013/photo92.webp";
import photo93 from "/public/images/events/coree/2013/photo93.webp";
import photo94 from "/public/images/events/coree/2013/photo94.webp";
import photo95 from "/public/images/events/coree/2013/photo95.webp";
import photo96 from "/public/images/events/coree/2013/photo96.webp";
import photo97 from "/public/images/events/coree/2013/photo97.webp";
import photo98 from "/public/images/events/coree/2013/photo98.webp";
import photo99 from "/public/images/events/coree/2013/photo99.webp";

export const coree2013Images = [
  {
    src: photo1,
    alt: "Championnat Corée 2013 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Championnat Corée 2013 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Championnat Corée 2013 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Championnat Corée 2013 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Championnat Corée 2013 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Championnat Corée 2013 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Championnat Corée 2013 - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Championnat Corée 2013 - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo9,
    alt: "Championnat Corée 2013 - Photo 9",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Championnat Corée 2013 - Photo 10",
    format: "3:2" as const,
  },
  {
    src: photo11,
    alt: "Championnat Corée 2013 - Photo 11",
    format: "3:2" as const,
  },
  {
    src: photo12,
    alt: "Championnat Corée 2013 - Photo 12",
    format: "3:2" as const,
  },
  {
    src: photo13,
    alt: "Championnat Corée 2013 - Photo 13",
    format: "3:2" as const,
  },
  {
    src: photo14,
    alt: "Championnat Corée 2013 - Photo 14",
    format: "3:2" as const,
  },
  {
    src: photo15,
    alt: "Championnat Corée 2013 - Photo 15",
    format: "3:2" as const,
  },
  {
    src: photo16,
    alt: "Championnat Corée 2013 - Photo 16",
    format: "3:2" as const,
  },
  {
    src: photo17,
    alt: "Championnat Corée 2013 - Photo 17",
    format: "3:2" as const,
  },
  {
    src: photo18,
    alt: "Championnat Corée 2013 - Photo 18",
    format: "3:2" as const,
  },
  {
    src: photo19,
    alt: "Championnat Corée 2013 - Photo 19",
    format: "3:2" as const,
  },
  {
    src: photo20,
    alt: "Championnat Corée 2013 - Photo 20",
    format: "3:2" as const,
  },
  {
    src: photo21,
    alt: "Championnat Corée 2013 - Photo 21",
    format: "3:2" as const,
  },
  {
    src: photo22,
    alt: "Championnat Corée 2013 - Photo 22",
    format: "3:2" as const,
  },
  {
    src: photo23,
    alt: "Championnat Corée 2013 - Photo 23",
    format: "3:2" as const,
  },
  {
    src: photo24,
    alt: "Championnat Corée 2013 - Photo 24",
    format: "3:2" as const,
  },
  {
    src: photo25,
    alt: "Championnat Corée 2013 - Photo 25",
    format: "3:2" as const,
  },
  {
    src: photo26,
    alt: "Championnat Corée 2013 - Photo 26",
    format: "3:2" as const,
  },
  {
    src: photo27,
    alt: "Championnat Corée 2013 - Photo 27",
    format: "3:2" as const,
  },
  {
    src: photo28,
    alt: "Championnat Corée 2013 - Photo 28",
    format: "3:2" as const,
  },
  {
    src: photo29,
    alt: "Championnat Corée 2013 - Photo 29",
    format: "3:2" as const,
  },
  {
    src: photo30,
    alt: "Championnat Corée 2013 - Photo 30",
    format: "3:2" as const,
  },
  {
    src: photo31,
    alt: "Championnat Corée 2013 - Photo 31",
    format: "3:2" as const,
  },
  {
    src: photo32,
    alt: "Championnat Corée 2013 - Photo 32",
    format: "3:2" as const,
  },
  {
    src: photo33,
    alt: "Championnat Corée 2013 - Photo 33",
    format: "3:2" as const,
  },
  {
    src: photo34,
    alt: "Championnat Corée 2013 - Photo 34",
    format: "3:2" as const,
  },
  {
    src: photo35,
    alt: "Championnat Corée 2013 - Photo 35",
    format: "2:3" as const,
  },
  {
    src: photo36,
    alt: "Championnat Corée 2013 - Photo 36",
    format: "3:2" as const,
  },
  {
    src: photo37,
    alt: "Championnat Corée 2013 - Photo 37",
    format: "3:2" as const,
  },
  {
    src: photo38,
    alt: "Championnat Corée 2013 - Photo 38",
    format: "3:2" as const,
  },
  {
    src: photo39,
    alt: "Championnat Corée 2013 - Photo 39",
    format: "3:2" as const,
  },
  {
    src: photo40,
    alt: "Championnat Corée 2013 - Photo 40",
    format: "3:2" as const,
  },
  {
    src: photo41,
    alt: "Championnat Corée 2013 - Photo 41",
    format: "3:2" as const,
  },
  {
    src: photo42,
    alt: "Championnat Corée 2013 - Photo 42",
    format: "3:2" as const,
  },
  {
    src: photo43,
    alt: "Championnat Corée 2013 - Photo 43",
    format: "3:2" as const,
  },
  {
    src: photo44,
    alt: "Championnat Corée 2013 - Photo 44",
    format: "3:2" as const,
  },
  {
    src: photo45,
    alt: "Championnat Corée 2013 - Photo 45",
    format: "3:2" as const,
  },
  {
    src: photo46,
    alt: "Championnat Corée 2013 - Photo 46",
    format: "3:2" as const,
  },
  {
    src: photo47,
    alt: "Championnat Corée 2013 - Photo 47",
    format: "3:2" as const,
  },
  {
    src: photo48,
    alt: "Championnat Corée 2013 - Photo 48",
    format: "3:2" as const,
  },
  {
    src: photo49,
    alt: "Championnat Corée 2013 - Photo 49",
    format: "3:2" as const,
  },
  {
    src: photo50,
    alt: "Championnat Corée 2013 - Photo 50",
    format: "3:2" as const,
  },
  {
    src: photo51,
    alt: "Championnat Corée 2013 - Photo 51",
    format: "2:3" as const,
  },
  {
    src: photo52,
    alt: "Championnat Corée 2013 - Photo 52",
    format: "3:2" as const,
  },
  {
    src: photo53,
    alt: "Championnat Corée 2013 - Photo 53",
    format: "3:2" as const,
  },
  {
    src: photo54,
    alt: "Championnat Corée 2013 - Photo 54",
    format: "3:2" as const,
  },
  {
    src: photo55,
    alt: "Championnat Corée 2013 - Photo 55",
    format: "3:2" as const,
  },
  {
    src: photo56,
    alt: "Championnat Corée 2013 - Photo 56",
    format: "3:2" as const,
  },
  {
    src: photo57,
    alt: "Championnat Corée 2013 - Photo 57",
    format: "3:2" as const,
  },
  {
    src: photo58,
    alt: "Championnat Corée 2013 - Photo 58",
    format: "3:2" as const,
  },
  {
    src: photo59,
    alt: "Championnat Corée 2013 - Photo 59",
    format: "3:2" as const,
  },
  {
    src: photo60,
    alt: "Championnat Corée 2013 - Photo 60",
    format: "3:2" as const,
  },
  {
    src: photo61,
    alt: "Championnat Corée 2013 - Photo 61",
    format: "2:3" as const,
  },
  {
    src: photo62,
    alt: "Championnat Corée 2013 - Photo 62",
    format: "3:2" as const,
  },
  {
    src: photo63,
    alt: "Championnat Corée 2013 - Photo 63",
    format: "3:2" as const,
  },
  {
    src: photo64,
    alt: "Championnat Corée 2013 - Photo 64",
    format: "3:2" as const,
  },
  {
    src: photo65,
    alt: "Championnat Corée 2013 - Photo 65",
    format: "3:2" as const,
  },
  {
    src: photo66,
    alt: "Championnat Corée 2013 - Photo 66",
    format: "3:2" as const,
  },
  {
    src: photo67,
    alt: "Championnat Corée 2013 - Photo 67",
    format: "3:2" as const,
  },
  {
    src: photo68,
    alt: "Championnat Corée 2013 - Photo 68",
    format: "2:3" as const,
  },
  {
    src: photo69,
    alt: "Championnat Corée 2013 - Photo 69",
    format: "3:2" as const,
  },
  {
    src: photo70,
    alt: "Championnat Corée 2013 - Photo 70",
    format: "3:2" as const,
  },
  {
    src: photo71,
    alt: "Championnat Corée 2013 - Photo 71",
    format: "3:2" as const,
  },
  {
    src: photo72,
    alt: "Championnat Corée 2013 - Photo 72",
    format: "2:3" as const,
  },
  {
    src: photo73,
    alt: "Championnat Corée 2013 - Photo 73",
    format: "3:2" as const,
  },
  {
    src: photo74,
    alt: "Championnat Corée 2013 - Photo 74",
    format: "3:2" as const,
  },
  {
    src: photo75,
    alt: "Championnat Corée 2013 - Photo 75",
    format: "3:2" as const,
  },
  {
    src: photo76,
    alt: "Championnat Corée 2013 - Photo 76",
    format: "3:2" as const,
  },
  {
    src: photo77,
    alt: "Championnat Corée 2013 - Photo 77",
    format: "3:2" as const,
  },
  {
    src: photo78,
    alt: "Championnat Corée 2013 - Photo 78",
    format: "3:2" as const,
  },
  {
    src: photo79,
    alt: "Championnat Corée 2013 - Photo 79",
    format: "3:2" as const,
  },
  {
    src: photo80,
    alt: "Championnat Corée 2013 - Photo 80",
    format: "3:2" as const,
  },
  {
    src: photo81,
    alt: "Championnat Corée 2013 - Photo 81",
    format: "3:2" as const,
  },
  {
    src: photo82,
    alt: "Championnat Corée 2013 - Photo 82",
    format: "3:2" as const,
  },
  {
    src: photo83,
    alt: "Championnat Corée 2013 - Photo 83",
    format: "3:2" as const,
  },
  {
    src: photo84,
    alt: "Championnat Corée 2013 - Photo 84",
    format: "3:2" as const,
  },
  {
    src: photo85,
    alt: "Championnat Corée 2013 - Photo 85",
    format: "2:3" as const,
  },
  {
    src: photo87,
    alt: "Championnat Corée 2013 - Photo 87",
    format: "3:2" as const,
  },
  {
    src: photo88,
    alt: "Championnat Corée 2013 - Photo 88",
    format: "3:2" as const,
  },
  {
    src: photo89,
    alt: "Championnat Corée 2013 - Photo 89",
    format: "3:2" as const,
  },
  {
    src: photo90,
    alt: "Championnat Corée 2013 - Photo 90",
    format: "3:2" as const,
  },
  {
    src: photo91,
    alt: "Championnat Corée 2013 - Photo 91",
    format: "3:2" as const,
  },
  {
    src: photo92,
    alt: "Championnat Corée 2013 - Photo 92",
    format: "3:2" as const,
  },
  {
    src: photo93,
    alt: "Championnat Corée 2013 - Photo 93",
    format: "3:2" as const,
  },
  {
    src: photo94,
    alt: "Championnat Corée 2013 - Photo 94",
    format: "3:2" as const,
  },
  {
    src: photo95,
    alt: "Championnat Corée 2013 - Photo 95",
    format: "3:2" as const,
  },
  {
    src: photo96,
    alt: "Championnat Corée 2013 - Photo 96",
    format: "2:3" as const,
  },
  {
    src: photo97,
    alt: "Championnat Corée 2013 - Photo 97",
    format: "3:2" as const,
  },
  {
    src: photo98,
    alt: "Championnat Corée 2013 - Photo 98",
    format: "2:3" as const,
  },
  {
    src: photo99,
    alt: "Championnat Corée 2013 - Photo 99",
    format: "3:2" as const,
  },
];
