import { MotionDiv } from "@/components/ui/MotionDiv";

interface TextAnimationProps {
  text: string | string[];
  position: "center" | "left";
  color?: "primary" | "secondary";
  className?: string;
  textPosition?: "center" | "left";
  CustomTextClasses?: string;
}

export const Title = ({
  text,
  position,
  color = "primary",
  CustomTextClasses,
  className,
  textPosition,
}: TextAnimationProps) => {
  const containerClasses =
    position === "center"
      ? "flex flex-col items-center"
      : "flex flex-col items-start";

  const textColorClasses = {
    primary: "text-primary",
    secondary: "text-secondary",
  };

  // Convertir le texte en tableau s'il ne l'est pas déjà
  const textLines = Array.isArray(text) ? text.slice(0, 3) : [text];

  // Calculer la largeur maximale parmi toutes les lignes
  const maxTextWidth = Math.max(...textLines.map((line) => line.length)) * 25;
  const underlineWidth = Math.max(maxTextWidth * 0.64, 100);

  return (
    <div className={`${containerClasses} ${className}`}>
      <div
        className={`relative ${position === "center" ? "flex flex-col items-center" : ""}`}
      >
        <div
          className={`flex flex-col ${textPosition === "center" ? "items-center" : "items-start"}`}
        >
          {textLines.map((line, index) => (
            <h2
              key={index}
              className={`relative z-10 whitespace-nowrap font-syne text-xl font-extrabold uppercase tracking-wider xs:text-2xl sm:text-3xl md:text-3xl lg:text-4xl ${CustomTextClasses} ${
                textColorClasses[color]
              } ${index !== textLines.length - 1 ? "mb-3" : "mb-[-10px]"}`}
            >
              {line}
            </h2>
          ))}
        </div>
        <MotionDiv
          className="relative z-0 h-4 bg-gradient-to-r from-[#F9F383] via-[#F14553] to-[#19477E] sm:h-6 md:h-8"
          style={{
            width: underlineWidth,
            marginLeft: position === "center" ? "auto" : "0",
            marginRight: position === "center" ? "auto" : "0",
          }}
          initial={{ scaleX: 0, originX: position === "center" ? 0.5 : 0 }}
          whileInView={{ scaleX: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
        />
      </div>
    </div>
  );
};
