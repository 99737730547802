import photo1 from "/public/images/events/houlgate/stage-houlgate-12/photo1.webp";
import photo2 from "/public/images/events/houlgate/stage-houlgate-12/photo2.webp";
import photo3 from "/public/images/events/houlgate/stage-houlgate-12/photo3.webp";
import photo4 from "/public/images/events/houlgate/stage-houlgate-12/photo4.webp";
import photo5 from "/public/images/events/houlgate/stage-houlgate-12/photo5.webp";
import photo6 from "/public/images/events/houlgate/stage-houlgate-12/photo6.webp";
import photo7 from "/public/images/events/houlgate/stage-houlgate-12/photo7.webp";

export const stageHoulgate12Images = [
  {
    src: photo1,
    alt: "Stage Houlgate 2012 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Stage Houlgate 2012 - Photo 2",
    format: "2:3" as const,
  },
  {
    src: photo3,
    alt: "Stage Houlgate 2012 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Stage Houlgate 2012 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Stage Houlgate 2012 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Stage Houlgate 2012 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Stage Houlgate 2012 - Photo 7",
    format: "3:2" as const,
  },
];
