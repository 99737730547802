import photo1 from "/public/images/events/championnat/championnat-coree-17/photo1.webp";
import photo2 from "/public/images/events/championnat/championnat-coree-17/photo2.webp";
import photo3 from "/public/images/events/championnat/championnat-coree-17/photo3.webp";

export const championnatCoree17Images = [
  {
    src: photo1,
    alt: "Championnat Corée 2017 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Championnat Corée 2017 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Championnat Corée 2017 - Photo 3",
    format: "3:2" as const,
  },
];
