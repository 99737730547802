import photo1 from "/public/images/events/championnat/championnat95&78-17/photo1.webp";
import photo2 from "/public/images/events/championnat/championnat95&78-17/photo2.webp";
import photo3 from "/public/images/events/championnat/championnat95&78-17/photo3.webp";
import photo4 from "/public/images/events/championnat/championnat95&78-17/photo4.webp";
import photo5 from "/public/images/events/championnat/championnat95&78-17/photo5.webp";
import photo6 from "/public/images/events/championnat/championnat95&78-17/photo6.webp";
import photo7 from "/public/images/events/championnat/championnat95&78-17/photo7.webp";
import photo8 from "/public/images/events/championnat/championnat95&78-17/photo8.webp";
import photo9 from "/public/images/events/championnat/championnat95&78-17/photo9.webp";
import photo10 from "/public/images/events/championnat/championnat95&78-17/photo10.webp";
import photo11 from "/public/images/events/championnat/championnat95&78-17/photo11.webp";

export const championnat957817Images = [
  {
    src: photo1,
    alt: "Championnat 1995-1978-2017 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Championnat 1995-1978-2017 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Championnat 1995-1978-2017 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Championnat 1995-1978-2017 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Championnat 1995-1978-2017 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Championnat 1995-1978-2017 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Championnat 1995-1978-2017 - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Championnat 1995-1978-2017 - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo9,
    alt: "Championnat 1995-1978-2017 - Photo 9",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Championnat 1995-1978-2017 - Photo 10",
    format: "3:2" as const,
  },
  {
    src: photo11,
    alt: "Championnat 1995-1978-2017 - Photo 11",
    format: "3:2" as const,
  },
];
