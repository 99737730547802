import { StaticImageData } from "next/image";

// Championnats
import { championnat95Images } from "./gallery/championnat/championnat95-24/championnat95-24";
import { championnat957817Images } from "./gallery/championnat/championnat95&78-17/championnat95&78-17";
import { championnat9519Images } from "./gallery/championnat/championnat95-19/championnat95-19";
import { championnatCoree17Images } from "./gallery/championnat/championnat-coree-17/championnat-coree-17";
import { championnatTechnique18Images } from "./gallery/championnat/championnat-technique-18/championnat-technique-18";
import { championnatFrance12Images } from "./gallery/championnat/championnat-france-12/championnat-france-12";

// Coupes & opens
import { coupeAmbassadeurCoreeImages } from "./gallery/coupes&open/coupe-ambassadeur-coree-24/coupe-ambassadeur-coree-24";
import { openIdfImages } from "./gallery/coupes&open/open-idf-24/open-idf-24";
import { openRosny18Images } from "./gallery/coupes&open/open-rosny-18/open-rosny-18";

// Houlgate
import { stageHoulgate12Images } from "./gallery/houlgate/stage-houlgate-12/stage-houlgate-12";
import { stageHoulgate14Images } from "./gallery/houlgate/stage-houlgate-14/stage-houlgate-14";
import { stageHoulgate18Images } from "./gallery/houlgate/stage-houlgate-18/stage-houlgate-18";
import { stageHoulgate24Images } from "./gallery/houlgate/stage-houlgate-24/stage-houlgate-24";
import { stageHoulgate25Images } from "./gallery/houlgate/stage-houlgate-25/stage-houlgate-25";

// Coree
import { coree2013Images } from "./gallery/coree/2013/coree-2013";

// Stages
import { stageLille09Images } from "./gallery/stages/stage-lille-09/stage-lille-09";
import { stageCombat13Images } from "./gallery/stages/stage-combat-13/stage-combat-13";
import { stage1ereDan25 } from "./gallery/stages/stage-1ere-dan-25/stage-1ere-dan-25";

// Demonstrations
import { demonstration2004 } from "./gallery/demonstrations/demonstration-2004";

// Téléthon
import { telethon24Images } from "./gallery/telethon/telethon-24/telethon24";
import { telethon17Images } from "./gallery/telethon/telethon-17/telethon-17";
import { forumAssociation21Images } from "./gallery/forum-association-21/forum-association-21";

// Not Listed
import { notListedImages } from "./gallery/not-listed/not-listed";

type ContentSegment = {
  text: string;
  isBold?: boolean;
};

type ContentItem = {
  type: "paragraph" | "list";
  segments: ContentSegment[];
  items?: string[];
};

export type EventImage = {
  src: StaticImageData;
  alt: string;
  format: "2:3" | "3:2";
};

export type Event = {
  id: string;
  title: string;
  description: string;
  date: string;
  location: string;
  imageBanner: StaticImageData;
  images?: EventImage[];
  eventLabel: string;
  labelSelection: string;
  content: ContentItem[];
  category: "competitions" | "stagesAndDemonstrations" | "Autres";
  desktopTitle: string[];
  mobileTitle: string[];
};

export const eventsData: Event[] = [
  // Not Listed
  {
    id: "not-listed",
    title: "Création du Mudo Club Argenteuil",
    description:
      "Le Mudo Club Argenteuil a été fondé le 21 octobre 1999, marquant le début d'une aventure exceptionnelle dans le monde du Taekwondo.",
    date: "1999-10-21T00:00:00Z",
    location: "Argenteuil",
    imageBanner: notListedImages[0].src,
    images: notListedImages,
    eventLabel: "NOT LISTED",
    labelSelection: "Création du Mudo Club Argenteuil",
    desktopTitle: ["Création", "Mudo Club"],
    mobileTitle: ["Création", "Mudo Club"],
    category: "Autres",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le 21 octobre 1999, le Mudo Club Argenteuil a été créé avec pour objectif de promouvoir le Taekwondo dans la région d'Argenteuil. Ce moment historique a marqué le début d'une aventure qui continue d'inspirer et de former des pratiquants passionnés.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Depuis sa création, le club n'a cessé de grandir, accueillant de nouveaux adhérents, et s'imposant comme une référence locale dans l'enseignement du Taekwondo.",
          },
        ],
      },
    ],
  },
  // 2004
  {
    id: "demonstration-2004",
    title: "Demonstration 2004",
    description: "Demonstration 2004",
    date: "2004-01-01T00:00:00Z",
    location: "Mudo Club Argenteuil",
    imageBanner: demonstration2004[0].src,
    images: demonstration2004,
    eventLabel: "DEMONSTRATION",
    labelSelection: "Demonstration 2004",
    desktopTitle: ["Demonstration", "2004"],
    mobileTitle: ["Demonstration", "2004"],
    category: "stagesAndDemonstrations",
    content: [],
  },
  // 2009
  {
    id: "stage-lille-2009",
    title: "Stage Lille - 2009",
    description:
      "En avril 2009, le Mudo Club Argenteuil a participé à un stage technique à Lille, offrant aux pratiquants une expérience enrichissante et formatrice.",
    date: "2009-04-11T00:00:00Z",
    location: "Lille",
    imageBanner: stageLille09Images[0].src,
    images: stageLille09Images,
    eventLabel: "STAGE",
    labelSelection: "Lille 2009",
    desktopTitle: ["Stage", "Lille 2009"],
    mobileTitle: ["Stage", "Lille 2009"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En avril 2009, le Mudo Club Argenteuil s'est rendu à Lille pour participer à un stage technique de Taekwondo. Cet événement a permis aux participants d'approfondir leurs compétences et de renforcer leur pratique sous la supervision d'instructeurs qualifiés.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage a été une occasion précieuse de progresser dans une ambiance conviviale, tout en partageant des moments forts avec d'autres pratiquants passionnés.",
          },
        ],
      },
    ],
  },

  // 2012
  {
    id: "stage-houlgate-2012",
    title: "Stage Houlgate - 2012",
    description:
      "En 2012, le Mudo Club Argenteuil a eu l'honneur de participer au stage technique d'Houlgate, un événement incontournable du Taekwondo français encadré par des experts et marqué par des moments de partage inoubliables.",
    date: "2012-05-12T00:00:00Z",
    location: "Houlgate",
    imageBanner: stageHoulgate12Images[0].src,
    images: stageHoulgate12Images,
    eventLabel: "STAGE",
    labelSelection: "Houlgate 2012",
    desktopTitle: ["Stage", "Houlgate", "2012"],
    mobileTitle: ["Stage", "Houlgate", "2012"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En mai 2012, nos pratiquants ont eu la chance de participer au célèbre ",
          },
          {
            text: "stage technique de Taekwondo à Houlgate",
            isBold: true,
          },
          {
            text: ". Ce rendez-vous emblématique a rassemblé des clubs venus de toute la France, offrant à chacun l’opportunité d’échanger, de progresser et de partager une passion commune.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Encadré par des experts de renom, le stage a permis aux participants d'approfondir leur maîtrise technique à travers un programme complet mêlant théorie et pratique. Les ateliers comprenaient :",
          },
        ],
      },
      {
        type: "list",
        segments: [
          {
            text: "Ateliers proposés :",
          },
        ],
        items: [
          "Techniques de combat avancées",
          "Perfectionnement des poomsés",
          "Séances de self-défense (Oh Shin Soul)",
          "Préparation physique et exercices spécifiques",
          "Moments d'échange et d’apprentissage collectif",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "L'ambiance chaleureuse et conviviale du stage a renforcé les liens entre les participants, tout en offrant un cadre propice à l'amélioration individuelle. Les conseils avisés des experts ont permis à chaque pratiquant de franchir un cap dans sa progression.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage restera gravé dans les mémoires comme une ",
          },
          {
            text: "expérience marquante",
            isBold: true,
          },
          {
            text: ", où technique, passion et camaraderie se sont harmonieusement mêlées. Un grand bravo à tous nos participants pour leur engagement et leur enthousiasme !",
          },
        ],
      },
    ],
  },

  {
    id: "championnat-france-2012",
    title: "Championnat de France - 2012",
    description:
      "Le Mudo Club Argenteuil a participé au championnat de France 2012, une compétition majeure qui a réuni les meilleurs combattants du club.",
    date: "2012-05-12T00:00:00Z",
    location: "Argenteuil",
    imageBanner: championnatFrance12Images[0].src,
    images: championnatFrance12Images,
    eventLabel: "COMPETITION",
    labelSelection: "Championnat France 2012",
    desktopTitle: ["Championnat", "France", "2012"],
    mobileTitle: ["Championnat", "France", "2012"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En 2012, le Mudo Club Argenteuil a eu l'honneur d'être représenté au ",
          },
          {
            text: "Championnat de France de Taekwondo",
            isBold: true,
          },
          {
            text: " par l'un de ses membres, après un beau parcours de qualification à travers les championnats du Val d'Oise et d'Île-de-France.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bien qu'aucune médaille n'ait été remportée lors de cette compétition nationale, notre représentant a livré un ",
          },
          {
            text: "combat remarquable",
            isBold: true,
          },
          {
            text: " face à des adversaires de haut niveau, démontrant la qualité de la formation dispensée au sein de notre club.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette participation au championnat de France représente une étape importante dans l'histoire du club, marquant notre capacité à former des athlètes capables d'évoluer au plus haut niveau national.",
          },
        ],
      },
    ],
  },
  // 2013
  {
    id: "stage-combat-2013",
    title: "Stage Combat - 2013",
    description:
      "En janvier 2013, un membre du Mudo Club Argenteuil a participé à un stage de combat exceptionnel à Poissy, encadré par le célèbre taekwondoïste français Mickaël Borot.",
    date: "2013-01-26T00:00:00Z",
    location: "Poissy",
    imageBanner: stageCombat13Images[0].src,
    images: stageCombat13Images,
    eventLabel: "STAGE",
    labelSelection: "Combat 2013",
    desktopTitle: ["Stage", "Combat", "2013"],
    mobileTitle: ["Stage", "Combat", "2013"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le 26 janvier 2013, le Mudo Club Argenteuil a été fièrement représenté lors d'un stage de combat organisé à Poissy. Cet événement exceptionnel a été dirigé par le renommé ",
          },
          {
            text: "Mickaël Borot",
            isBold: true,
          },
          {
            text: ", l'un des taekwondoïstes français les plus reconnus pour son expertise et son palmarès impressionnant.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Au cours de ce stage, notre membre a pu perfectionner ses techniques de combat, bénéficiant des conseils avisés et de l'expérience unique de Mickaël Borot. Cette opportunité a permis de mettre en lumière le talent et la détermination de notre club dans le domaine du Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce moment fort restera gravé comme une belle occasion de progresser dans l'art du combat tout en portant fièrement les couleurs du Mudo Club Argenteuil.",
          },
        ],
      },
    ],
  },

  {
    id: "stage-coree-2013",
    title: "Stage Corée - 2013",
    description:
      "Le Mudo Club Argenteuil a participé au stage de Corée 2013, une compétition majeure qui a réuni les meilleurs combattants du club.",
    date: "2013-07-14T00:00:00Z",
    location: "Gwangju, Corée du Sud",
    imageBanner: coree2013Images[0].src,
    images: coree2013Images,
    eventLabel: "STAGE",
    labelSelection: "Corée 2013",
    desktopTitle: ["Stage", "Corée", "2013"],
    mobileTitle: ["Stage", "Corée", "2013"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En juillet 2013, le Mudo Club Argenteuil a eu l'immense privilège de participer à un stage exceptionnel en ",
          },
          {
            text: "Corée du Sud",
            isBold: true,
          },
          {
            text: ", berceau du Taekwondo. Cet événement a été une opportunité unique pour les participants de plonger au cœur de la tradition et de l'art martial, guidés par le Grand Maître ",
          },
          {
            text: "Kim Jong WAN, 9ème dan",
            isBold: true,
          },
          {
            text: ", une véritable référence mondiale.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage a réuni de nombreux clubs venus de toute la France, renforçant ainsi l'esprit de communauté et permettant des échanges riches entre pratiquants. Dans un cadre empreint de culture et d'histoire, les participants ont découvert le Taekwondo sous un nouveau jour, avec un programme varié et intensif.",
          },
        ],
      },
      {
        type: "list",
        segments: [
          {
            text: "Les ateliers proposés incluaient :",
          },
        ],
        items: [
          "Techniques de combat avancées pour perfectionner les enchaînements et stratégies",
          "Poomsaes traditionnels pour approfondir l'esthétique et la précision des mouvements",
          "Oh Shin Soul (self-défense), permettant d'appliquer des techniques martiales dans des situations réelles",
          "Découverte de la musique traditionnelle coréenne, apportant une dimension culturelle unique",
          "Initiation à l'art de la calligraphie, pour mieux comprendre l'héritage artistique et spirituel du Taekwondo",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Encadrés par le Grand Maître Kim Jong WAN, les participants ont bénéficié d'une transmission directe de l'excellence martiale, avec un accompagnement personnalisé et des conseils précieux. Cette immersion totale a permis de progresser techniquement tout en se connectant aux racines profondes de l'art martial.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage restera gravé dans les mémoires comme une expérience riche et inoubliable, mêlant apprentissage intensif, partage et découverte culturelle. Un moment fort pour tous les participants, qui ont eu l'occasion de vivre le Taekwondo à la source, en Corée.",
          },
        ],
      },
    ],
  },
  // 2014
  {
    id: "stage-houlgate-2014",
    title: "Stage Houlgate - 2014",
    description:
      "En mai 2014, le Mudo Club Argenteuil a participé au stage annuel de Houlgate, une expérience marquante pour tous les participants, alliant apprentissage technique et convivialité.",
    date: "2014-05-10T00:00:00Z",
    location: "Houlgate",
    imageBanner: stageHoulgate14Images[0].src,
    images: stageHoulgate14Images,
    eventLabel: "STAGE",
    labelSelection: "Houlgate 2014",
    desktopTitle: ["Stage", "Houlgate", "2014"],
    mobileTitle: ["Stage", "Houlgate", "2014"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En 2014, le Mudo Club Argenteuil a participé au prestigieux stage de Taekwondo organisé à Houlgate. Cet événement a une fois de plus rassemblé des clubs et pratiquants de toute la France, offrant un cadre idéal pour perfectionner sa pratique et s'immerger dans l'esprit martial.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Sous la direction d'instructeurs expérimentés, les participants ont pu approfondir leurs connaissances techniques et renforcer leurs compétences en combat, poomsés et self-défense. Chaque séance était conçue pour offrir un équilibre entre rigueur martiale et moments de convivialité, permettant à chacun de progresser à son rythme.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "L'atmosphère chaleureuse et le cadre exceptionnel d'Houlgate ont contribué à faire de ce stage un moment inoubliable. Entre les entraînements intensifs et les échanges avec d'autres passionnés, les participants ont vécu une expérience enrichissante sur le plan sportif et humain.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour notre club, cette édition 2014 a été une occasion précieuse de renforcer l'esprit d'équipe et de partager des souvenirs marquants. Ce stage illustre une fois encore l'importance de ces rendez-vous annuels, qui permettent de conjuguer progrès technique et valeurs fondamentales du Taekwondo.",
          },
        ],
      },
    ],
  },

  // 2017
  {
    id: "championnat-coree-2017",
    title: "Championnat International de Poomsées - Corée 2017",
    description:
      "En juillet 2017, Maître Wilfried Avegnon a brillé lors du championnat international de poomsées en Corée du Sud, remportant la médaille d'or et inscrivant notre club dans l'élite mondiale.",
    date: "2017-07-14T00:00:00Z",
    location: "Séoul, Corée du Sud",
    imageBanner: championnatCoree17Images[0].src,
    images: championnatCoree17Images,
    eventLabel: "COMPETITION",
    labelSelection: "Corée 2017",
    desktopTitle: ["Championnat", "Corée", "2017"],
    mobileTitle: ["Championnat", "Corée", "2017"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En juillet 2017, Maître Wilfried Avegnon a représenté notre club au prestigieux ",
          },
          {
            text: "Championnat International de Poomsées",
            isBold: true,
          },
          {
            text: " en Corée du Sud. Sa performance exceptionnelle lui a permis de décrocher la médaille d'or.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette victoire est un témoignage éclatant de son talent, de son engagement et de la qualité de la formation dispensée au sein de notre club. Une immense fierté pour toute notre communauté !",
          },
        ],
      },
    ],
  },
  {
    id: "telethon-2017",
    title: "Téléthon - 2017",
    description:
      "Le Mudo Club Argenteuil a organisé des activités spéciales pour le Téléthon 2017, collectant 125,10 € en moins d'une heure pour soutenir cette cause.",
    date: "2017-12-09T00:00:00Z",
    location: "Mudo Club Argenteuil",
    imageBanner: telethon17Images[0].src,
    images: telethon17Images,
    eventLabel: "CARITATIF",
    labelSelection: "Téléthon 2017",
    desktopTitle: ["Téléthon", "2017"],
    mobileTitle: ["Téléthon", "2017"],
    category: "Autres",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le samedi 9 décembre 2017, le Mudo Club Argenteuil s'est mobilisé pour le ",
          },
          {
            text: "Téléthon",
            isBold: true,
          },
          {
            text: ". Une journée placée sous le signe de la solidarité et du partage, avec des activités qui ont ravi petits et grands.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Au programme : un parcours du combattant pour tous les participants, des combats en série pour les amateurs d'action, et un stand sucré bien garni pour reprendre des forces. Une ambiance conviviale et sportive qui a permis d'attirer de nouvelles recrues au club !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Grâce à votre générosité, ",
          },
          {
            text: "125,10 € ont été collectés en moins d'une heure",
            isBold: true,
          },
          {
            text: ", une somme intégralement reversée au Téléthon pour soutenir la recherche et les familles.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Un grand merci à tous les participants, bénévoles et spectateurs pour leur implication. Votre soutien est précieux et nous permet de contribuer à cette belle cause. ",
          },
          {
            text: "Bravo à tous !",
            isBold: true,
          },
        ],
      },
    ],
  },
  {
    id: "championnats-departementaux-cdt-78-95",
    title: "Championnats Départementaux CDT 78/95",
    description:
      "Le Mudo Club Argenteuil a participé aux championnats départementaux Val d'Oise et Yvelines, une compétition réunissant plus de 350 combattants au gymnase du 3ème millénaire à Cergy.",
    date: "2017-12-13T00:00:00Z",
    location: "Gymnase du 3ème millénaire, Cergy",
    imageBanner: championnat957817Images[0].src,
    images: championnat957817Images,
    eventLabel: "COMPETITION",
    labelSelection: "CDT 78/95 2017",
    desktopTitle: ["Championnats", "CDT 78/95"],
    mobileTitle: ["Championnats", "78/95"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le dimanche 3 décembre 2023, le Mudo Club Argenteuil a fièrement participé aux ",
          },
          {
            text: "Championnats Départementaux Val d'Oise et Yvelines",
            isBold: true,
          },
          {
            text: ", organisés par Maître Wilfried Avegnon au gymnase du 3ème millénaire à Cergy.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cet événement a rassemblé plus de ",
          },
          {
            text: "350 combattants",
            isBold: true,
          },
          {
            text: ", féminins et masculins, répartis dans les catégories suivantes : benjamins (9-10 ans), minimes (11-12 ans), cadets (13-14 ans), juniors (15-17 ans) et séniors (plus de 18 ans).",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cinq combattants du Mudo Club ont pris part à cette compétition. Bien qu'aucune médaille n'ait été remportée, ils ont livré de beaux combats, témoignant de leur détermination et de leur esprit sportif.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bravo à tous nos participants pour leur engagement et leur combativité ! Ce type d'événement est une belle occasion pour nos athlètes de gagner en expérience et de se mesurer à des adversaires de qualité.",
          },
        ],
      },
    ],
  },

  // 2018
  {
    id: "stage-houlgate-2018",
    title: "Stage Houlgate - 2018",
    description:
      "Un stage technique inoubliable à Houlgate avec Maître Kim, combinant entraînements sur la plage et travail technique en salle.",
    date: "2018-04-07T00:00:00Z",
    location: "Houlgate",
    imageBanner: stageHoulgate18Images[23].src,
    images: stageHoulgate18Images,
    eventLabel: "STAGE",
    labelSelection: "Houlgate 2018",
    desktopTitle: ["Stage Houlgate", "2018"],
    mobileTitle: ["Stage", "Houlgate", "2018"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Les 7 et 8 avril 2018, nos pratiquants ont eu l'immense honneur de participer au célèbre ",
          },
          {
            text: "stage technique de Taekwondo à Houlgate",
            isBold: true,
          },
          {
            text: " sous la direction du ",
          },
          {
            text: "Grand Maître Kim Jong WAN, 9ème dan",
            isBold: true,
          },
          {
            text: ". Cette expérience unique a mêlé travail technique intensif et moments de partage inoubliables.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Le programme du stage alternait entre des séances de ",
          },
          {
            text: "jogging et exercices matinaux sur la plage",
            isBold: true,
          },
          {
            text: ", offrant un cadre naturel propice au renforcement physique, et des entraînements en salle axés sur la technique et la révision des poomsés.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Sous les instructions rigoureuses du ",
          },
          {
            text: "Grand Maître Kim Jong WAN",
            isBold: true,
          },
          {
            text: ", chaque participant a pu perfectionner ses techniques et approfondir sa compréhension des poomsés. Ces moments de transmission directe resteront gravés dans les mémoires.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage a été une ",
          },
          {
            text: "expérience enrichissante",
            isBold: true,
          },
          {
            text: " pour tous les participants, offrant une opportunité exceptionnelle de progresser tout en partageant des moments conviviaux avec la communauté du Taekwondo.",
          },
        ],
      },
    ],
  },
  {
    id: "open-rosny-sous-bois-2018",
    title: "Open de Rosny-sous-Bois - 2018",
    description:
      "Le Mudo Club Argenteuil a brillé lors de l'Open de Rosny-sous-Bois le 12 mai 2018, ramenant plusieurs médailles grâce à ses jeunes combattants.",
    date: "2018-05-12T00:00:00Z",
    location: "Rosny-sous-Bois",
    imageBanner: openRosny18Images[0].src,
    images: openRosny18Images,
    eventLabel: "COMPETITION",
    labelSelection: "Open Rosny 2018",
    desktopTitle: ["Open Rosny-sous-Bois", "2018"],
    mobileTitle: ["Open", "Rosny", "2018"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le samedi 12 mai 2018, huit jeunes combattants du Mudo Club Argenteuil ont participé à l'",
          },
          {
            text: "Open de Rosny-sous-Bois",
            isBold: true,
          },
          {
            text: ", une compétition intense qui a permis à nos athlètes de se distinguer par leur talent et leur détermination.",
          },
        ],
      },
      {
        type: "list",
        segments: [
          {
            text: "Résultats des médaillés :",
          },
        ],
        items: [
          "Bronze : Darius",
          "Bronze : Sarah",
          "Argent : Manel",
          "Argent : Daniel (après 5 combats !)",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Chaque participant a démontré un esprit sportif exemplaire et des performances remarquables tout au long de cette journée. Mention spéciale à ",
          },
          {
            text: "Daniel",
            isBold: true,
          },
          {
            text: ", qui a décroché une médaille d'argent après avoir livré pas moins de cinq combats acharnés !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Félicitations à tous nos champions pour leurs médailles et leur combativité. Vous avez fait la fierté du club et montré une fois de plus que le travail et la persévérance paient !",
          },
        ],
      },
    ],
  },
  {
    id: "championnats-techniques-2018",
    title: "Championnats Techniques - 2018",
    description:
      "Le Mudo Club Argenteuil a participé aux championnats techniques 2018 en Île-de-France et à l'échelle nationale, décrochant une médaille d'argent en catégorie Master 1.",
    date: "2018-07-02T00:00:00Z",
    location: "Bagneux & Bron",
    imageBanner: championnatTechnique18Images[0].src,
    images: championnatTechnique18Images,
    eventLabel: "COMPETITION",
    labelSelection: "Championnat technique 2018",
    desktopTitle: ["Championnats Techniques", "2018"],
    mobileTitle: ["Championnats", "Techniques", "2018"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le 1er avril 2018, le Mudo Club Argenteuil a participé au ",
          },
          {
            text: "championnat technique (poomsé) d'Île-de-France",
            isBold: true,
          },
          {
            text: " à Bagneux. Ce championnat régional a vu l'un de nos représentants décrocher une magnifique ",
          },
          {
            text: "médaille d'argent",
            isBold: true,
          },
          {
            text: " dans la catégorie Master 1 (41 - 50 ans), une belle performance qui reflète l'engagement et la qualité de notre formation.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Quelques semaines plus tard, le 5 mai, le club a également participé au ",
          },
          {
            text: "championnat technique (poomsé) de France",
            isBold: true,
          },
          {
            text: " à Bron, près de Lyon. Une compétition nationale de haut niveau où nos représentants ont fait preuve de combativité et de technique face à une concurrence redoutable.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bien qu'aucune médaille n'ait été remportée lors du championnat national, cette expérience reste extrêmement enrichissante pour nos athlètes et témoigne de leur capacité à évoluer dans des compétitions de grande envergure.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bravo à tous nos participants pour leur travail acharné et leur esprit sportif. Cette année encore, le Mudo Club Argenteuil a su briller par son implication et sa détermination !",
          },
        ],
      },
    ],
  },

  // 2019
  {
    id: "championnat-departemental-95-2019",
    title: "Championnat Départemental du Val d'Oise - 2019",
    description:
      "En novembre 2019, le championnat départemental du Val d'Oise s'est tenu à Argenteuil, rassemblant clubs, combattants et encadrants pour une journée mémorable.",
    date: "2019-11-09T00:00:00Z",
    location: "Argenteuil",
    imageBanner: championnat9519Images[0].src,
    images: championnat9519Images,
    eventLabel: "COMPETITION",
    labelSelection: "CDT 95 2019",
    desktopTitle: ["Championnat", "Départemental 95", "2019"],
    mobileTitle: ["Championnat", "95", "2019"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "En novembre 2019, Argenteuil a accueilli le ",
          },
          {
            text: "Championnat Départemental du Val d'Oise",
            isBold: true,
          },
          {
            text: ", un événement incontournable qui a rassemblé les meilleurs combattants du département.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette journée mémorable a été marquée par des moments forts tels que la ",
          },
          {
            text: "séance de casse",
            isBold: true,
          },
          {
            text: " dirigée par Maître Wilfried Avegnon, les performances des petits champions, et le podium des clubs participants.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Les valeureux combattants, soutenus par leurs équipes encadrantes, ont démontré une belle combativité et un esprit sportif exemplaire tout au long de la journée. Un grand bravo à tous pour leur engagement et leur énergie !",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Des photos souvenir ont immortalisé ces moments marquants, reflétant l'intensité, la camaraderie et la joie qui ont animé cet événement.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Merci à tous les participants, bénévoles et spectateurs pour avoir contribué à faire de ce championnat une réussite !",
            isBold: true,
          },
        ],
      },
    ],
  },

  // 2021
  {
    id: "forum-associations-2021",
    title: "Forum des Associations - 2021",
    description:
      "Le Mudo Club a participé au forum des associations le 11 septembre 2021 avec une démonstration impressionnante réalisée par ses jeunes et ados.",
    date: "2021-09-11T00:00:00Z",
    location: "Argenteuil",
    imageBanner: forumAssociation21Images[0].src,
    images: forumAssociation21Images,
    eventLabel: "DÉMONSTRATION",
    labelSelection: "Forum 2021",
    desktopTitle: ["Forum des Associations", "2021"],
    mobileTitle: ["Forum", "Associations", "2021"],
    category: "Autres",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le samedi 11 septembre 2021, le Mudo Club a fièrement participé au ",
          },
          {
            text: "forum des associations",
            isBold: true,
          },
          {
            text: ", offrant aux visiteurs une démonstration spectaculaire et dynamique.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Les jeunes et ados du club ont brillé à travers des démonstrations de ",
          },
          {
            text: "mouvements de base, raquette, poomsé, casse et combat",
            isBold: true,
          },
          {
            text: ", illustrant leur maîtrise et leur passion pour le Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ces performances ont captivé le public, mettant en lumière le talent de nos adhérents et la qualité de l'enseignement dispensé au sein du club.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bravo à tous nos participants pour leur énergie et leur engagement ! Nous leur souhaitons une excellente saison sportive et de nombreux succès à venir.",
            isBold: true,
          },
        ],
      },
    ],
  },

  // 2024
  {
    id: "stage-houlgate-2024",
    title: "Stage Houlgate - 2024",
    description:
      "Stage national annuel dirigé par le Grand Maître Kim Jong WAN 9ème dan, une référence incontournable du Taekwondo français. Un rendez-vous incontournable qui rassemble des clubs de toute la France pour plusieurs jours d'entraînement intensif.",
    date: "2024-04-13T00:00:00Z",
    location: "Houlgate",
    imageBanner: stageHoulgate24Images[0].src,
    images: stageHoulgate24Images,
    eventLabel: "STAGE",
    labelSelection: "Houlgate 2024",
    desktopTitle: ["Stage Houlgate", "2024"],
    mobileTitle: ["Stage", "Houlgate", "2024"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Chaque année, notre club a l'immense privilège de participer au stage emblématique d'Houlgate, dirigé par le ",
          },
          {
            text: "Grand Maître Kim Jong WAN, 9ème dan",
            isBold: true,
          },
          {
            text: ". Véritable pilier du Taekwondo, son enseignement d'une qualité exceptionnelle fait de cet événement un rendez-vous incontournable pour tous les passionnés.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pendant plusieurs jours, des pratiquants de tous niveaux, venus des quatre coins de la France, se réunissent pour une immersion totale dans l'art du Taekwondo. Entre apprentissage intensif et ambiance chaleureuse, ce stage offre un cadre idéal pour progresser techniquement et renforcer les liens avec la communauté du Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour nos adhérents, ce stage représente une ",
          },
          {
            text: "opportunité exceptionnelle",
            isBold: true,
          },
          {
            text: " de s'entraîner sous les instructions rigoureuses du Grand Maître Kim, de perfectionner leurs techniques, et de vivre pleinement les valeurs fondamentales de notre art martial. Les journées sont ponctuées de sessions variées, permettant à chacun d'explorer tous les aspects du Taekwondo dans un environnement propice au dépassement de soi.",
          },
        ],
      },
    ],
  },
  {
    id: "championnat-95-2024",
    title: "Championnat 95 - 2024",
    description:
      "Championnat départemental du Val d'Oise 2024, une compétition mettant en valeur les meilleurs talents du département.",
    date: "2024-11-11T00:00:00Z",
    location: "Argenteuil",
    imageBanner: championnat95Images[0].src,
    images: championnat95Images,
    eventLabel: "COMPETITION",
    labelSelection: "CDT 95 2024",
    desktopTitle: ["Championnat 95", "2024"],
    mobileTitle: ["Championnat", "95", "2024"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Notre club a eu l'honneur et la fierté d'organiser et d'accueillir le championnat départemental du Val d'Oise 2024. Un événement majeur qui a ",
          },
          {
            text: "rencontré un franc succès",
            isBold: true,
          },
          {
            text: " grâce à l'implication de tous nos bénévoles et membres du club.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Nos combattants et coachs ont brillamment représenté le club tout au long de cette journée intense, démontrant leur excellent niveau technique et leur esprit sportif exemplaire.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette journée restera mémorable pour notre club qui a assuré l'organisation complète de l'événement. La forte participation de nos athlètes, couplée à une ambiance sportive exceptionnelle, a donné lieu à de nombreuses performances remarquables. Les retours enthousiastes des participants et des officiels confirment la réussite totale de cet événement.",
          },
        ],
      },
    ],
  },
  {
    id: "open-idf-2024",
    title: "Open Idf - 2024",
    description:
      "L'Open International d'Île-de-France 2024, une compétition prestigieuse qui s'est déroulée les 16 et 17 novembre à Paris, où notre club a brillé grâce à une magnifique médaille de bronze.",
    date: "2024-11-16T00:00:00Z",
    location: "Paris",
    imageBanner: openIdfImages[0].src,
    images: openIdfImages,
    eventLabel: "COMPETITION",
    labelSelection: "Open IDF 2024",
    desktopTitle: ["Open IDF", "2024"],
    mobileTitle: ["Open", "IDF", "2024"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "L'Open International d'Île-de-France, qui s'est tenu les 16 et 17 novembre 2024 à Paris, a été marqué par la ",
          },
          {
            text: "superbe performance",
            isBold: true,
          },
          {
            text: " de l'une de nos adhérentes qui a décroché une magnifique médaille de bronze.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette compétition de haut niveau, qui attire des athlètes de toute la France et de l'international, a été l'occasion pour notre club de briller grâce à notre représentante qui a fait preuve d'un engagement et d'une détermination exemplaires tout au long de la compétition.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette médaille de bronze vient récompenser les efforts et le travail acharné de notre athlète, et représente une grande fierté pour l'ensemble du club. Une performance qui démontre une fois de plus la qualité de la formation dispensée au sein de notre structure.",
          },
        ],
      },
    ],
  },
  {
    id: "coupe-ambassadeur-coree-2024",
    title: "Coupe Ambassadeur Corée - 2024",
    description:
      "Coupe Ambassadeur Corée 2024, une compétition majeure qui rassemble les meilleurs athlètes du département pour une journée de compétition intense et spectaculaire.",
    date: "2024-12-14T00:00:00Z",
    location: "Paris",
    imageBanner: coupeAmbassadeurCoreeImages[0].src,
    images: coupeAmbassadeurCoreeImages,
    eventLabel: "COMPETITION",
    labelSelection: "Coupe Ambassadeur 2024",
    desktopTitle: ["Coupe", "Ambassadeur Corée", "2024"],
    mobileTitle: ["Coupe", "Ambassadeur", "Corée"],
    category: "competitions",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "La Coupe Ambassadeur Corée représente l'un des événements les plus prestigieux du calendrier. Cette année, notre club a été ",
          },
          {
            text: "brillamment représenté",
            isBold: true,
          },
          {
            text: " par l'une de nos adhérentes qui a décroché une magnifique médaille d'argent dans sa catégorie.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette performance exceptionnelle témoigne de la qualité de la formation dispensée au sein de notre club et de l'engagement de nos athlètes. Une fierté pour l'ensemble du club et une source d'inspiration pour tous nos pratiquants.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette médaille d'argent représente non seulement une reconnaissance du travail accompli, mais aussi une expérience enrichissante au plus haut niveau pour notre athlète. Sa performance démontre le potentiel de notre club à former des champions et à les accompagner vers l'excellence.",
          },
        ],
      },
    ],
  },
  {
    id: "telethon-2024",
    title: "Telethon - 2024",
    description:
      "Le Mudo Club Argenteuil s'est mobilisé pour le Téléthon 2024 avec une série d'activités caritatives et sportives, collectant des fonds pour soutenir cette belle cause.",
    date: "2024-12-07T00:00:00Z",
    location: "Argenteuil",
    imageBanner: telethon24Images[0].src,
    images: telethon24Images,
    eventLabel: "CARITATIF",
    labelSelection: "Téléthon 2024",
    desktopTitle: ["Telethon", "2024"],
    mobileTitle: ["Telethon", "2024"],
    category: "Autres",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le samedi 7 décembre 2024, le Mudo Club Argenteuil a une fois de plus montré son engagement envers des causes importantes en participant activement au ",
          },
          {
            text: "Téléthon",
            isBold: true,
          },
          {
            text: ". Cet événement caritatif a rassemblé nos membres, bénévoles et visiteurs dans une ambiance conviviale et solidaire.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Au programme : des démonstrations spectaculaires, des combats amicaux, et des activités pour petits et grands, qui ont permis de mettre en avant les valeurs de partage et de solidarité portées par notre club.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Grâce à la générosité des participants et des spectateurs, nous avons pu collecter des fonds qui seront entièrement reversés au Téléthon, contribuant ainsi à soutenir la recherche et les familles.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Un grand merci à tous ceux qui ont participé, que ce soit en donnant, en aidant à l'organisation ou en assistant à nos activités. Votre soutien fait toute la différence et permet au Mudo Club Argenteuil de continuer à promouvoir des initiatives solidaires.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Bravo à tous pour cette belle réussite collective ! Vive le sport, vive la solidarité !",
            isBold: true,
          },
        ],
      },
    ],
  },
  {
    id: "stage-preparation-1er-dan-2025",
    title: "Stage Préparation 1er Dan - 2025",
    description:
      "Stage de préparation au passage de grade 1er Dan, organisé par le Comité Départemental de Taekwondo. Une opportunité unique pour les candidats de se préparer dans les meilleures conditions à cet examen important.",
    date: "2025-01-26T00:00:00Z",
    location: "Herblay",
    imageBanner: stage1ereDan25[0],
    eventLabel: "STAGE",
    labelSelection: "1er Dan 2025",
    desktopTitle: ["Stage Préparation", "1er Dan 2025"],
    mobileTitle: ["Stage", "1er Dan", "2025"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le Comité Départemental de Taekwondo organise un ",
          },
          {
            text: "stage essentiel de préparation",
            isBold: true,
          },
          {
            text: " pour tous les candidats au passage de grade 1er Dan. Cette journée intensive permettra aux participants de se familiariser avec tous les aspects de l'examen.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Au programme, une préparation complète couvrant l'ensemble des épreuves : Poomsaes, vocabulaire technique (kibon), hanbon kyorugi, self-défense (oh Shin Soul), combats, ainsi qu'une préparation au QCM portant sur la vie associative, l'actualité du Taekwondo et l'arbitrage.",
          },
        ],
      },
      {
        type: "list",
        segments: [
          {
            text: "Points importants :",
          },
        ],
        items: [
          "Inscription obligatoire auprès de votre professeur principal",
          "Stage ouvert aux ceintures rouges préparant leur 1er Dan",
          "Programme complet couvrant toutes les épreuves de l'examen",
          "Encadrement par des experts qualifiés",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Ce stage représente une ",
          },
          {
            text: "opportunité unique",
            isBold: true,
          },
          {
            text: " de se préparer dans les meilleures conditions et de bénéficier des conseils d'experts pour aborder sereinement l'examen du 1er Dan.",
          },
        ],
      },
    ],
  },
  {
    id: "stage-houlgate-2025",
    title: "Stage Houlgate - 2025",
    description:
      "Stage national annuel dirigé par le Grand Maître Kim Jong WAN 9ème dan, combinant entraînements en salle et sessions en plein air sur la plage. Une expérience unique mêlant technique pure, combat, self-défense et relaxation dans un cadre exceptionnel.",
    date: "2025-04-12T00:00:00Z",
    location: "Houlgate",
    imageBanner: stageHoulgate25Images[0].src,
    images: stageHoulgate25Images,
    eventLabel: "STAGE",
    labelSelection: "Houlgate 2025",
    desktopTitle: ["Stage Houlgate", "2025"],
    mobileTitle: ["Stage", "Houlgate", "2025"],
    category: "stagesAndDemonstrations",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour la nouvelle édition 2025, ",
          },
          {
            text: "Maître Kim Jong WAN",
            isBold: true,
          },
          {
            text: " nous fait l'honneur d'organiser à nouveau son stage emblématique à Houlgate. Ce stage intensif, réservé aux clubs partenaires, propose une approche complète et unique du Taekwondo.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Le programme, particulièrement riche et varié, alterne entre sessions en salle et entraînements sur la plage. Au menu : technique pure, combat, self-défense, préparation physique avec travail cardio, sessions de frappe sur raquettes, et séances de relaxation pour une récupération optimale.",
          },
        ],
      },
      {
        type: "list",
        segments: [
          {
            text: "Points clés du stage :",
          },
        ],
        items: [
          "Stage réservé aux licenciés du club",
          "Encadrement par Maître Kim Jong WAN, 9ème dan",
          "Programme complet alternant différentes pratiques",
          "Cadre exceptionnel entre salle et plage",
          "Inscriptions gérées directement par le club",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette formation privilégiée représente une ",
          },
          {
            text: "opportunité exceptionnelle",
            isBold: true,
          },
          {
            text: " de progresser sous la direction d'un grand maître, dans un environnement propice au dépassement de soi et au partage. L'alternance entre pratique intensive et moments de détente en fait un stage particulièrement enrichissant.",
          },
        ],
      },
    ],
  },
];
