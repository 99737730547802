import photo1 from "/public/images/events/coupes&open/open-idf-24/photo1.webp";
import photo2 from "/public/images/events/coupes&open/open-idf-24/photo2.webp";

export const openIdfImages = [
  {
    src: photo1,
    alt: "Open Idf 2024 - Photo 1",
    format: "2:3" as const,
  },
  {
    src: photo2,
    alt: "Open Idf 2024 - Photo 2",
    format: "2:3" as const,
  },
];
