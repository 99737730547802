import photo1 from "/public/images/events/houlgate/stage-houlgate-18/photo1.webp";
import photo2 from "/public/images/events/houlgate/stage-houlgate-18/photo2.webp";
import photo3 from "/public/images/events/houlgate/stage-houlgate-18/photo3.webp";
import photo4 from "/public/images/events/houlgate/stage-houlgate-18/photo4.webp";
import photo5 from "/public/images/events/houlgate/stage-houlgate-18/photo5.webp";
import photo6 from "/public/images/events/houlgate/stage-houlgate-18/photo6.webp";
import photo7 from "/public/images/events/houlgate/stage-houlgate-18/photo7.webp";
import photo8 from "/public/images/events/houlgate/stage-houlgate-18/photo8.webp";
import photo9 from "/public/images/events/houlgate/stage-houlgate-18/photo9.webp";
import photo10 from "/public/images/events/houlgate/stage-houlgate-18/photo10.webp";
import photo11 from "/public/images/events/houlgate/stage-houlgate-18/photo11.webp";
import photo12 from "/public/images/events/houlgate/stage-houlgate-18/photo12.webp";
import photo13 from "/public/images/events/houlgate/stage-houlgate-18/photo13.webp";
import photo14 from "/public/images/events/houlgate/stage-houlgate-18/photo14.webp";
import photo15 from "/public/images/events/houlgate/stage-houlgate-18/photo15.webp";
import photo16 from "/public/images/events/houlgate/stage-houlgate-18/photo16.webp";
import photo17 from "/public/images/events/houlgate/stage-houlgate-18/photo17.webp";
import photo18 from "/public/images/events/houlgate/stage-houlgate-18/photo18.webp";
import photo19 from "/public/images/events/houlgate/stage-houlgate-18/photo19.webp";
import photo20 from "/public/images/events/houlgate/stage-houlgate-18/photo20.webp";
import photo21 from "/public/images/events/houlgate/stage-houlgate-18/photo21.webp";
import photo22 from "/public/images/events/houlgate/stage-houlgate-18/photo22.webp";
import photo23 from "/public/images/events/houlgate/stage-houlgate-18/photo23.webp";
import photo24 from "/public/images/events/houlgate/stage-houlgate-18/photo24.webp";
import photo25 from "/public/images/events/houlgate/stage-houlgate-18/photo25.webp";
import photo26 from "/public/images/events/houlgate/stage-houlgate-18/photo26.webp";

export const stageHoulgate18Images = [
  {
    src: photo1,
    alt: "Stage Houlgate 2018 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Stage Houlgate 2018 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Stage Houlgate 2018 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Stage Houlgate 2018 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Stage Houlgate 2018 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Stage Houlgate 2018 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Stage Houlgate 2018 - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Stage Houlgate 2018 - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo9,
    alt: "Stage Houlgate 2018 - Photo 9",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Stage Houlgate 2018 - Photo 10",
    format: "3:2" as const,
  },
  {
    src: photo11,
    alt: "Stage Houlgate 2018 - Photo 11",
    format: "3:2" as const,
  },
  {
    src: photo12,
    alt: "Stage Houlgate 2018 - Photo 12",
    format: "3:2" as const,
  },
  {
    src: photo13,
    alt: "Stage Houlgate 2018 - Photo 13",
    format: "2:3" as const,
  },
  {
    src: photo14,
    alt: "Stage Houlgate 2018 - Photo 14",
    format: "2:3" as const,
  },
  {
    src: photo15,
    alt: "Stage Houlgate 2018 - Photo 15",
    format: "2:3" as const,
  },
  {
    src: photo16,
    alt: "Stage Houlgate 2018 - Photo 16",
    format: "3:2" as const,
  },
  {
    src: photo17,
    alt: "Stage Houlgate 2018 - Photo 17",
    format: "3:2" as const,
  },
  {
    src: photo18,
    alt: "Stage Houlgate 2018 - Photo 18",
    format: "2:3" as const,
  },
  {
    src: photo19,
    alt: "Stage Houlgate 2018 - Photo 19",
    format: "3:2" as const,
  },
  {
    src: photo20,
    alt: "Stage Houlgate 2018 - Photo 20",
    format: "3:2" as const,
  },
  {
    src: photo21,
    alt: "Stage Houlgate 2018 - Photo 21",
    format: "2:3" as const,
  },
  {
    src: photo22,
    alt: "Stage Houlgate 2018 - Photo 22",
    format: "3:2" as const,
  },
  {
    src: photo23,
    alt: "Stage Houlgate 2018 - Photo 23",
    format: "3:2" as const,
  },
  {
    src: photo24,
    alt: "Stage Houlgate 2018 - Photo 24",
    format: "3:2" as const,
  },
  {
    src: photo25,
    alt: "Stage Houlgate 2018 - Photo 25",
    format: "3:2" as const,
  },
  {
    src: photo26,
    alt: "Stage Houlgate 2018 - Photo 26",
    format: "3:2" as const,
  },
];
