import photo1 from "/public/images/events/demonstrations/2004/photo1.webp";
import photo2 from "/public/images/events/demonstrations/2004/photo2.webp";
import photo3 from "/public/images/events/demonstrations/2004/photo3.webp";
import photo4 from "/public/images/events/demonstrations/2004/photo4.webp";
import photo5 from "/public/images/events/demonstrations/2004/photo5.webp";
import photo6 from "/public/images/events/demonstrations/2004/photo6.webp";
import photo7 from "/public/images/events/demonstrations/2004/photo7.webp";
import photo8 from "/public/images/events/demonstrations/2004/photo8.webp";
import photo9 from "/public/images/events/demonstrations/2004/photo9.webp";
import photo10 from "/public/images/events/demonstrations/2004/photo10.webp";

export const demonstration2004 = [
  {
    src: photo1,
    alt: "Demonstration 2004 - Photo 1",
    format: "2:3" as const,
  },
  {
    src: photo2,
    alt: "Demonstration 2004 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Demonstration 2004 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Demonstration 2004 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Demonstration 2004 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Demonstration 2004 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Demonstration 2004 - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Demonstration 2004 - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo9,
    alt: "Demonstration 2004 - Photo 9",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Demonstration 2004 - Photo 10",
    format: "3:2" as const,
  },
];
