import photo1 from "/public/images/events/championnat/championnat-france-12/photo1.webp";
import photo2 from "/public/images/events/championnat/championnat-france-12/photo2.webp";
import photo3 from "/public/images/events/championnat/championnat-france-12/photo3.webp";
import photo4 from "/public/images/events/championnat/championnat-france-12/photo4.webp";
import photo5 from "/public/images/events/championnat/championnat-france-12/photo5.webp";
import photo6 from "/public/images/events/championnat/championnat-france-12/photo6.webp";

export const championnatFrance12Images = [
  {
    src: photo1,
    alt: "Championnat France 2012 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Championnat France 2012 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Championnat France 2012 - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo4,
    alt: "Championnat France 2012 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Championnat France 2012 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Championnat France 2012 - Photo 6",
    format: "3:2" as const,
  },
];
