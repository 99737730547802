import photo1 from "/public/images/events/not-listed/photo1.webp";
import photo2 from "/public/images/events/not-listed/photo2.webp";
import photo3 from "/public/images/events/not-listed/photo3.webp";
import photo5 from "/public/images/events/not-listed/photo5.webp";
import photo6 from "/public/images/events/not-listed/photo6.webp";
import photo7 from "/public/images/events/not-listed/photo7.webp";
import photo8 from "/public/images/events/not-listed/photo8.webp";
import photo10 from "/public/images/events/not-listed/photo10.webp";
import photo11 from "/public/images/events/not-listed/photo11.webp";
import photo12 from "/public/images/events/not-listed/photo12.webp";
import photo13 from "/public/images/events/not-listed/photo13.webp";
import photo14 from "/public/images/events/not-listed/photo14.webp";
import photo15 from "/public/images/events/not-listed/photo15.webp";
import photo16 from "/public/images/events/not-listed/photo16.webp";
import photo17 from "/public/images/events/not-listed/photo17.webp";
import photo18 from "/public/images/events/not-listed/photo18.webp";
import photo19 from "/public/images/events/not-listed/photo19.webp";

export const notListedImages = [
  {
    src: photo1,
    alt: "Not Listed - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Not Listed - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Not Listed - Photo 3",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Not Listed - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Not Listed - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Not Listed - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Not Listed - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Not Listed - Photo 10",
    format: "2:3" as const,
  },
  {
    src: photo11,
    alt: "Not Listed - Photo 11",
    format: "3:2" as const,
  },
  {
    src: photo12,
    alt: "Not Listed - Photo 12",
    format: "3:2" as const,
  },
  {
    src: photo13,
    alt: "Not Listed - Photo 13",
    format: "3:2" as const,
  },
  {
    src: photo14,
    alt: "Not Listed - Photo 14",
    format: "3:2" as const,
  },
  {
    src: photo15,
    alt: "Not Listed - Photo 15",
    format: "3:2" as const,
  },
  {
    src: photo16,
    alt: "Not Listed - Photo 16",
    format: "3:2" as const,
  },
  {
    src: photo17,
    alt: "Not Listed - Photo 17",
    format: "3:2" as const,
  },
  {
    src: photo18,
    alt: "Not Listed - Photo 18",
    format: "3:2" as const,
  },
  {
    src: photo19,
    alt: "Not Listed - Photo 19",
    format: "3:2" as const,
  },
];
