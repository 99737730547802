import photo1 from "/public/images/events/stages/stage-lille-09/photo1.webp";
import photo2 from "/public/images/events/stages/stage-lille-09/photo2.webp";
import photo3 from "/public/images/events/stages/stage-lille-09/photo3.webp";
import photo4 from "/public/images/events/stages/stage-lille-09/photo4.webp";
import photo5 from "/public/images/events/stages/stage-lille-09/photo5.webp";

export const stageLille09Images = [
  {
    src: photo1,
    alt: "Stage Lille 2009 - Photo 1",
    format: "3:2" as const,
  },
  {
    src: photo2,
    alt: "Stage Lille 2009 - Photo 2",
    format: "3:2" as const,
  },
  {
    src: photo3,
    alt: "Stage Lille 2009 - Photo 3",
    format: "2:3" as const,
  },
  {
    src: photo4,
    alt: "Stage Lille 2009 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Stage Lille 2009 - Photo 5",
    format: "3:2" as const,
  },
];
