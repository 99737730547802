import photo1 from "/public/images/events/houlgate/stage-houlgate-14/photo1.webp";
import photo2 from "/public/images/events/houlgate/stage-houlgate-14/photo2.webp";
import photo3 from "/public/images/events/houlgate/stage-houlgate-14/photo3.webp";
import photo4 from "/public/images/events/houlgate/stage-houlgate-14/photo4.webp";
import photo5 from "/public/images/events/houlgate/stage-houlgate-14/photo5.webp";
import photo6 from "/public/images/events/houlgate/stage-houlgate-14/photo6.webp";
import photo7 from "/public/images/events/houlgate/stage-houlgate-14/photo7.webp";
import photo8 from "/public/images/events/houlgate/stage-houlgate-14/photo8.webp";
import photo9 from "/public/images/events/houlgate/stage-houlgate-14/photo9.webp";
import photo10 from "/public/images/events/houlgate/stage-houlgate-14/photo10.webp";

export const stageHoulgate14Images = [
  {
    src: photo1,
    alt: "Stage Houlgate 2014 - Photo 1",
    format: "2:3" as const,
  },
  {
    src: photo2,
    alt: "Stage Houlgate 2014 - Photo 2",
    format: "2:3" as const,
  },
  {
    src: photo3,
    alt: "Stage Houlgate 2014 - Photo 3",
    format: "2:3" as const,
  },
  {
    src: photo4,
    alt: "Stage Houlgate 2014 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Stage Houlgate 2014 - Photo 5",
    format: "3:2" as const,
  },
  {
    src: photo6,
    alt: "Stage Houlgate 2014 - Photo 6",
    format: "3:2" as const,
  },
  {
    src: photo7,
    alt: "Stage Houlgate 2014 - Photo 7",
    format: "3:2" as const,
  },
  {
    src: photo8,
    alt: "Stage Houlgate 2014 - Photo 8",
    format: "3:2" as const,
  },
  {
    src: photo9,
    alt: "Stage Houlgate 2014 - Photo 9",
    format: "3:2" as const,
  },
  {
    src: photo10,
    alt: "Stage Houlgate 2014 - Photo 10",
    format: "3:2" as const,
  },
];
