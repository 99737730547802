// coupe ambassadeur Corée

import photo1 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo1.webp";
import photo2 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo2.webp";
import photo3 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo3.webp";
import photo4 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo4.webp";
import photo5 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo5.webp";
import photo6 from "/public/images/events/coupes&open/coupe-ambassadeur-coree-24/photo6.webp";

export const coupeAmbassadeurCoreeImages = [
  {
    src: photo1,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 1",
    format: "2:3" as const,
  },
  {
    src: photo2,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 2",
    format: "2:3" as const,
  },
  {
    src: photo3,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 3",
    format: "2:3" as const,
  },
  {
    src: photo4,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 4",
    format: "3:2" as const,
  },
  {
    src: photo5,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 5",
    format: "2:3" as const,
  },
  {
    src: photo6,
    alt: "Coupe Ambassadeur Corée 2024 - Photo 6",
    format: "2:3" as const,
  },
];
